export const routesMenu: any[] = [
    {
        title: 'Recorregut Centre Històric',
        url: '/map/centrehistoric'
    },
    {
        title: 'Recorregut Castell',
        url: '/map/castell'
    },
    {
        title: 'Recorregut Miramar-Les Illes',
        url: '/map/miramarilles'
    }
];