import { Component, OnInit } from '@angular/core';
import {homeMenu} from '../../menus/HomeMenu';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  public items = homeMenu;
  public collaborators: string[];

  constructor() {

    this.collaborators = [
        'Amics del Cavall de Vila-seca',
        'Amics del Teatre de Vila-seca',
        'Associació de Comerciants i Empresaris de Vila-seca',
        'Associació de Reis',
        'Associació de Veïns de la Pineda',
        'Associació de Veïns de la Plana',
        'Conservatori Professional de Música de Vila-seca',
        'Coral Nova Unió',
        'Cantaires de la Pineda',
        'Creu Roja',
        'Esbart Dansaire Ramon d\'Olzina',
        'Esplai l\'Esclop',
        'Gegants i Grallers de Vila-seca',
        'Grup de Teatre la Tramoia',
        'Patronat Municipal de Turisme de Vila-seca',
        'Punt d\'Informació Juvenil de Vila-seca',
        'SER Catalunya',
        'Societat El Centru',
        
    ];

  }

  ngOnInit() {
  }

}
