import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  currentMap;
  map;
  return;

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.map = this.route.snapshot.paramMap.get('map');
    console.log(this.map);
    if (this.map === 'centrehistoric' || this.map === 'castell' || this.map === 'miramarilles') {
      this.return = '/delivery';
    } else if (this.map === 'busties') {
      this.return = '/office';
    } else {
      this.return = '/cavalcade';
    }

    // delivery
    if (this.map === 'centrehistoric') {
      this.map = 'https://www.google.com/maps/d/embed?mid=1QH6U5rmKSFEXTwq7hUd_TTePabTLdb6S&z=15';
    } else if (this.map === 'castell') {
      this.map = 'https://www.google.com/maps/d/embed?mid=10CJvviLR5Xsh6FwjcZO-C3QEdGkJW_FN&z=15';
    } else if (this.map === 'miramarilles') {
      this.map = 'https://www.google.com/maps/d/embed?mid=1j8vw_CBTibAigUsOd6u_Bu_E5Ph6gill&z=15';
    }
    // cavalcade
    else if (this.map === 'c-vila-seca') {
      this.map = 'https://www.google.com/maps/d/embed?mid=1sD4TNK8bBYr-g519gPA5KQeuPOh_A2ZT&z=15';
    } else if (this.map === 'c-pineda') {
      this.map = 'https://www.google.com/maps/d/embed?mid=1BHsBE9mq4eZQ-fPH9-f90TBfopx4xMts&z=16';
    } else if (this.map === 'c-plana') {
      this.map = 'https://www.google.com/maps/d/embed?mid=1mNctq66MIc97iYFWDNZYPhMlQ9zpy2bG&z=16';
    }
    // office
    else if (this.map === 'busties') {
      this.map =
        'https://www.google.com/maps/d/embed?mid=1h8Ca0RR9Ohp020A5H50WjsAxKoeP3fuJ&hl=es&z=13';
    }

    // load map
    this.currentMap = this.sanitizer.bypassSecurityTrustResourceUrl(this.map);
  }
}
