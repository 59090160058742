export const mainMenu: any[] = [
    {
        title: 'Inici',
        url: '/home',
        icon: 'assets/menu/1.png'
    },
    {
        title: 'Programa',
        url: '/schedule',
        icon: 'assets/menu/2.png'
    },
    {
        title: 'Oficina Postal, Haimes i Bústies Reials SES Majestats els Reis d’Orient',
        url: '/office',
        icon: 'assets/menu/3.png'
    },
    {
        title: 'Cavalcada de Reis',
        url: '/cavalcade',
        icon: 'assets/menu/4.png'
    },
    {
        title: 'Normes de seguretat per a la Cavalcada',
        url: '/rules',
        icon: 'assets/menu/6.png'
    },
    {
        title: 'Repartiment paquets de Reis',
        url: '/delivery',
        icon: 'assets/menu/5.png'
    },
    {
        title: 'Enviar carta als Reis',
        url: '/letter',
        icon: 'assets/menu/7.png'
    }
];
