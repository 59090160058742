import { Component,  ElementRef,  OnInit, ViewChild } from '@angular/core';
import {routesMenu} from '../../menus/RoutesMenu';
import {ActionSheetController, Platform, ToastController} from '@ionic/angular';
import {FormBuilder, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import {FilePath} from '@ionic-native/file-path/ngx';
import {File} from '@ionic-native/file/ngx';
import { Plugins } from '@capacitor/core';
import { VideoCapturePlus, VideoCapturePlusOptions } from '@ionic-native/video-capture-plus/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Router } from '@angular/router';

const { Filesystem } = Plugins;


@Component({
  selector: 'app-letter',
  templateUrl: './letter.component.html',
  styleUrls: ['./letter.component.scss'],
})
export class LetterComponent implements OnInit {
  public menuItems = routesMenu;
  public isLoading = false;

  public form;
  public submitted = false;
  public isDesktop = true;

  private blobData = null;
  private mediaTp = null;
  private desktopFile = null;


  constructor(
      public actionSheetController: ActionSheetController,
      private formBuilder: FormBuilder,
      private http: HttpClient,
      private platform: Platform,
      private camera: Camera,
      private videoCapturePlus: VideoCapturePlus,
      private file: File,
      private filePath: FilePath,
      public toastController: ToastController,
      public iab: InAppBrowser,
      private router: Router
  ) {
    if (this.platform.is('cordova')) {
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
    }

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', [Validators.required]],
      age: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      wiseMan: ['', [Validators.required]],
      auth: ['true', []]
    });
  }

  ngOnInit() { }

  get f() {
    return this.form.controls;
  }

  async sendLetter() {
    this.submitted = true;
    const checImage = this.isDesktop ? this.desktopFile === null : this.blobData === null;

    if (this.form.invalid || checImage) {
      return;
    }

    if(this.form.get('auth').value === 'true'){
      try{
        const formData = new FormData();
        const formValues = this.form.getRawValue();

        if(this.isDesktop){
          formData.append('media', this.desktopFile, this.desktopFile.name);
        }else{
          formData.append('media', this.blobData, 'image.jpg');
        }

        formData.set('name', formValues.name);
        formData.set('surname', formValues.surname);
        formData.set('age', formValues.age);
        formData.set('wiseMan', formValues.wiseMan);
        formData.set('mediaType', this.mediaTp);

        await this.makeHttpReq(formData);
      } catch (e) {
        await this.toastMessage('No s\'ha pogut enviar la carta. Torna-ho a intentar.');
      }
    }else{
      // simulate send
      this.router.navigate(['/success']);
    }
  }

  async downloadLetter() {
    window.open('https://reisvila-seca.cat/img/carta_reis_mags_2021.jpg','_system');
  }

  async pickPicture(mediaType, sourceType) {

    const options: CameraOptions = {
      quality: 80,
      mediaType,
      sourceType,
    };

    let base64;
    let enc;

    if (mediaType === this.camera.MediaType.PICTURE) {
      options.encodingType = this.camera.EncodingType.JPEG;
      enc = 'image/jpeg';
      this.mediaTp = 'image';
    } else {
      enc = 'video/mp4';
      this.mediaTp = 'video';
    }

    try {

      let data;
      if (mediaType === this.camera.MediaType.VIDEO && sourceType === this.camera.PictureSourceType.CAMERA) {

        const videoOptions: VideoCapturePlusOptions = {
          limit: 1
        };

        data = (await this.videoCapturePlus.captureVideo(videoOptions))[0].fullPath;
      } else {
        data = await this.camera.getPicture(options);
      }

      let correctPath;
      if (this.platform.is('android')) {
        correctPath = await this.filePath.resolveNativePath('file://' + data);
      } else {
        correctPath = data;
      }

      base64 = (await Filesystem.readFile({path: correctPath})).data;
      this.blobData = await this.b64toBlob(base64.toString(), enc);
    } catch (e) {
      await this.toastMessage('No s\'ha pogut carregar la carta');
    }
  }

  async makeHttpReq(data: any) {
    return this.http.post(
        environment.endpoint + '/letter', data
    ).subscribe(res => {
      this.form.reset();
      this.toastMessage('Carta enviada correctament');
      this.router.navigate(['/success']);
    }, err => {
      console.log('Error making http request');
      console.log(err.message);
      this.toastMessage('No s\'ha pogut enviar la carta. Torna-ho a intentar.');
    });
  }


  async toastMessage(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    await toast.present();
  }

  async showPicker() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Tria una opció',
      buttons: [
      {
        text: 'Capturar imatge',
        handler: () => {
          this.pickPicture(
              this.camera.MediaType.PICTURE,
              this.camera.PictureSourceType.CAMERA
          );
        }
      },
      {
        text: 'Capturar video',
        handler: () => {
          this.pickPicture(
              this.camera.MediaType.VIDEO,
              this.camera.PictureSourceType.CAMERA
          );
        }
      },
      {
        text: 'Seleccionar imatge',
        handler: () => {
          this.pickPicture(
              this.camera.MediaType.PICTURE,
              this.camera.PictureSourceType.PHOTOLIBRARY
          );
        }
      },
      {
        text: 'Seleccionar video',
        handler: () => {
          this.pickPicture(
              this.camera.MediaType.VIDEO,
              this.camera.PictureSourceType.PHOTOLIBRARY
          );
        }
      },
      {
        text: 'Cancel·lar',
        role: 'cancel'
      }
      ]
    });

    await actionSheet.present();

  }

  async onFileChange(event) {
    try {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        const mimeType = file.type;

        if (mimeType.split('/')[0] === 'image') {
          this.mediaTp = 'image';
        } else if (mimeType.split('/')[0] === 'video') {
          this.mediaTp = 'video';
        } else {
          await this.toastMessage('Format carta no valid');
          return;
        }

        this.desktopFile = file;
      }
    } catch (e) {
      await this.toastMessage('No s\'ha pogut enviar la carta. Torna-ho a intentar.');
    }
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

}
