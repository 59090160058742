export const homeMenu: any[] = [
    {
        title: 'Programa',
        url: '/schedule',
        icon: 'archive'
    },
    {
        title: 'Oficina postal i bústies reials',
        url: '/office',
        icon: 'heart'
    },
    {
        title: 'Cavalcada de Reis',
        url: '/cavalcade',
        icon: 'archive'
    },
    {
        title: 'Normes de seguretat per a la Cavalcada',
        url: '/rules',
        icon: 'archive'
    },
    {
        title: 'Repartiment de paquets dels Reis',
        url: '/delivery',
        icon: 'warning'
    }
];
