import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Platform} from '@ionic/angular';

declare var google;

@Component({
  selector: 'app-gmap',
  templateUrl: './gmap.component.html',
  styleUrls: ['./gmap.component.scss'],
})
export class GmapComponent implements OnInit {

  public markers = [
    {
      title: 'La Pineda 43481, Tarragona',
      lat: 41.076083,
      lon: 1.181666
    },
    {
      title: 'Carrer d\'Almería, 29 43480 La Plana, Tarragona',
      lat: 41.129632,
      lon: 1.128473
    },
    {
      title: 'Vilaseca 43480, Tarragona',
      lat: 41.107446,
      lon: 1.148742
    },

    {
      title: 'Vilaseca 43480, Tarragona',
      lat: 41.107854,
      lon: 1.145424
    },
    {
      title: 'Vilaseca 43480, Tarragona',
      lat: 41.109854,
      lon: 1.147960
    },
    {
      title: 'Carrer del Requet de Fèlix 35, 43480 Vila-seca, Tarragona',
      lat: 41.110953,
      lon: 1.148982
    },
    {
      title: 'Plaça Església 43480 Vila-seca, Tarragona\n',
      lat: 41.111120,
      lon: 1.145206
    },
    {
      title: 'Vilaseca 43480, Tarragona',
      lat: 41.111604,
      lon: 1.142861
    }
  ];

  @ViewChild('map', { static: false }) mapElement: ElementRef;
  map: any;

  constructor(public platform: Platform) {

  }

  async ngOnInit() {

    await this.platform.ready();

    const latLng = new google.maps.LatLng(41.117300, 1.159032);
    const mapOptions = {
      center: latLng,
      zoom: 12,
    };

    const map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    for (const marker of this.markers) {
      // tslint:disable-next-line:no-unused-expression
      new google.maps.Marker({
        position: new google.maps.LatLng(marker.lat, marker.lon),
        map,
        title: marker.title,
      });
    }

    this.map = map;

  }

}
