import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cavalcade',
  templateUrl: './cavalcade.component.html',
  styleUrls: ['./cavalcade.component.scss'],
})
export class CavalcadeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

}
