import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {ScheduleComponent} from './pages/schedule/schedule.component';
import {HomeComponent} from './pages/home/home.component';
import { DeliveryComponent } from './pages/delivery/delivery.component';
import {OfficeComponent} from './pages/office/office.component';
import {CavalcadeComponent} from './pages/cavalcade/cavalcade.component';
import {RulesComponent} from './pages/rules/rules.component';
import {LetterComponent} from './pages/letter/letter.component';
import { MapComponent } from './pages/map/map.component';
import { PrehomeComponent } from './pages/prehome/prehome.component';
import {GmapComponent} from './pages/gmap/gmap.component';
import { SuccessComponent } from './pages/success/success.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/prehome',
    pathMatch: 'full'
  },
  {
    path: 'prehome',
    component: PrehomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'schedule',
    component: ScheduleComponent
  },
  {
    path: 'office',
    component: OfficeComponent
  },
  {
    path: 'delivery',
    component: DeliveryComponent
  },
  {
    path: 'cavalcade',
    component: CavalcadeComponent
  },
  {
    path: 'rules',
    component: RulesComponent
  },
  {
    path: 'letter',
    component: LetterComponent
  },
  {
    path: 'map/:map',
    component: MapComponent
  },
  {
    path: 'gmap',
    component: GmapComponent
  },
  {
    path: 'success',
    component: SuccessComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
