import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleComponent implements OnInit {
  public monthName = [
    'Desembre',
    'Gener',
    // 'Febrer',
    // 'Març',
    // 'Abril',
    // 'Maig',
    // 'Juny',
    // 'Juliol',
    // 'Agost',
    // 'Setembre',
    // 'Octubre',
    // 'Novembre',
  ];
  public monthNum = [
    '12',
    '01',
    // '02',
    // '03',
    // '04',
    // '05',
    // '06',
    // '07',
    // '08',
    // '09',
    // '10',
    // '11',
  ];

  public monthDays = this.fillMonthDays();

  public selectedMonth = '';
  public selectedDay = '';

  public usedMonths = [];
  public months: any;
  public filterTerm: string;

  public isShown = false;

  constructor(private http: HttpClient) {
    this.getEvents();
  }

  private fillMonthDays() {
    const largeMonths = [1, 3, 5, 7, 8, 10, 12];
    const monthDays = {};
    for (let i = 1; i <= 12; i++) {
      if (largeMonths.includes(i)) {
        monthDays[this.monthName[i - 1]] = 31;
      } else if (i === 2) {
        monthDays['Febrer'] = 28;
      } else {
        monthDays[this.monthName[i - 1]] = 30;
      }
    }
    return monthDays;
  }

  ngOnInit() {}

  getEvents() {
    //let headers = new Headers({ 'Content-Type': 'application/json' });
    let headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    return this.http.get<any>(environment.endpoint + '/events', { headers: headers }).subscribe(
      (res) => {
        this.processEvents(res['data']);
      },
      (error) => {
        console.log('error');
        console.log(error);
      }
    );
  }

  processEvents(data: any) {
    const obj = {};

    for (const event of data) {
      const date = event.date;
      const monthNum = date.substr(3, 2);
      const index = this.monthNum.indexOf(monthNum);
      const month = this.monthName[index];

      if (obj.hasOwnProperty(month)) {
        obj[month].push(event);
      } else {
        obj[month] = [event];
      }
    }

    this.usedMonths = Object.keys(obj);
    this.months = obj;

    // tslint:disable-next-line:forin
    for (const month in this.months) {
      const days = [];
      const events = this.months[month];
      for (const event of events) {
        const day = event.date.substr(0, 2);
        if (days.hasOwnProperty(day)) {
          days[day].push(event);
        } else {
          days[day] = [event];
        }
        this.months[month] = days;
      }
    }
  }

  fourdaysPassed(events: any, i: number){  
    if(events.length <3){
      let x = 0;
      events.forEach(event => {
        x++;
      });
      x--;
      if(x == events.length){
        return true;
      }
      return false;
    }else if(events.length >= 3){
      i++;
      return i%4 == 0;
    }
    return false;
  }

  arrayOne(n: number): any[] {
    const array = [];
    for (let i = 1; i <= n; i++) {
      array.push(i);
    }
    return array;
  }

  getMonthDays(month) {
    return Object.keys(this.months[month]).sort();
  }

  correctDayFormat(num) {
    if (num === '') {
      return '';
    } else if (num < 10) {
      return '0' + num;
    } else {
      return '' + num;
    }
  }

  toggleShow() {
    this.isShown = !this.isShown;
  }

  async opendlink(link) {
    if (!link.match(/^https?:\/\//i)) {
      link = 'http://' + link;
    }
    window.open(link, '_system');
  }
}
