import { Component, OnInit } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { mainMenu } from "./menus/MainMenu";
import { Router } from "@angular/router";
import { Storage } from "@ionic/storage-angular";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages;
  public popup;
  private splash = true;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    public router: Router
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.storage.create();

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.appPages = mainMenu;

    this.splash = await this.storage.get("splash");
  }

  showSplash(): boolean {
    return this.splash != false;
  }

  setSplash() {
    this.splash = false;
    this.storage?.set("splash", this.splash);
  }

  ngOnInit() {}

  close(){
    this.popup = false;
  }
}
