import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage-angular';
import {IonicModule, IonicRouteStrategy, Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HomeComponent} from './pages/home/home.component';
import { DeliveryComponent } from './pages/delivery/delivery.component';
import { OfficeComponent } from './pages/office/office.component';
import {CavalcadeComponent} from './pages/cavalcade/cavalcade.component';
import { HttpClientModule } from '@angular/common/http';
import {ScheduleComponent} from './pages/schedule/schedule.component';
import {MapComponent} from './pages/map/map.component';
import {GmapComponent} from './pages/gmap/gmap.component';
import {PrehomeComponent} from './pages/prehome/prehome.component';
import {LetterComponent} from './pages/letter/letter.component';
import { RulesComponent } from './pages/rules/rules.component';

import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {File} from '@ionic-native/file/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import {WebView} from '@ionic-native/ionic-webview/ngx';
import {FilePath} from '@ionic-native/file-path/ngx';
import { VideoCapturePlus } from '@ionic-native/video-capture-plus/ngx';
import {ScheduleFilter} from './pipes/ScheduleFilter';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DeliveryComponent,
    OfficeComponent,
    DeliveryComponent,
    CavalcadeComponent,
    ScheduleComponent,
    LetterComponent,
    MapComponent,
    PrehomeComponent,
    GmapComponent,
    ScheduleComponent,
    ScheduleFilter,
    RulesComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicStorageModule.forRoot()
  ],
  providers: [
    StatusBar,
    SplashScreen,
    FormBuilder,
    Platform,
    Camera,
    File,
    FormBuilder,
    Platform,
    WebView,
    FilePath,
    VideoCapturePlus,
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
