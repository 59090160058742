import { Component, OnInit } from '@angular/core';
import {routesMenu} from '../../menus/RoutesMenu';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss'],
})
export class DeliveryComponent implements OnInit {

  public menuItems = routesMenu;
  constructor() { }

  ngOnInit() {}

}
